import { Injectable } from "@angular/core";
import { UNITS, UnitConfig, formatUnit } from "@enersis/gp-components/number-formatting";
import { TranslateService } from "@ngx-translate/core";
import { flatMap } from "lodash";
import {
  AnyFactorEmissionResponseDto,
  FactorEndpointAttributes,
  FactorEndpointId,
  FactorGenericKeyValueDto,
  FactorProperty,
  IFactorResponseDtoMap,
  IFactorTransportation_VehicleDto
} from "./factor-management.endpoint-config";
import { FactorManagementService } from "./factor-management.service";
import { CategoriesConfig, FactorSubCategories, FactorSubCategory } from "./factor-management.table-config";
import { ColumnConfig, TableData } from "./table/table.interface";

const [FIRST_YEAR, LAST_YEAR] = [1990, 2023];

function getScaleBetweenUnits(
  category: UnitConfig["category"],
  base: UnitConfig["base"],
  target: UnitConfig["base"]
): number {
  const baseScale = UNITS[category].find((unit) => unit.base === base).scale;
  const targetScale = UNITS[category].find((unit) => unit.base === target).scale;
  if (!baseScale || !targetScale) return 1;
  return baseScale / targetScale;
}

function getUnitConfigFromFactorProperty(factorProperty: FactorProperty): UnitConfig {
  return {
    ...factorProperty,
    base: factorProperty.displayedUnit,
    onlyUnits: [factorProperty.displayedUnit],
    numberFormat: { maximumFractionDigits: 20 } // we want all the precision!
  } as UnitConfig; // need to cast because of type shenanigans
}

function getDisplayedUnit(unitConfig: UnitConfig) {
  // use the unitConfig to generate something like "1.000 kg CO₂ / kWh" and cut off the number:
  return formatUnit(1, unitConfig).split("\xA0").splice(1).join("\xA0");
}

@Injectable({
  providedIn: "root"
})
export class FactorManagementTransformerService {
  constructor(
    private readonly factorManagementService: FactorManagementService,
    private readonly translate: TranslateService
  ) {}

  public getSubcategoryConfig(subCategoryId: FactorSubCategories): Omit<FactorSubCategory, "endpointsCh"> | undefined {
    const country = this.factorManagementService.getTenantCountry();
    const subCategoriesAtlas: Record<string, FactorSubCategory> = {};
    CategoriesConfig.forEach(({ subCategories }) =>
      subCategories.forEach((category) => (subCategoriesAtlas[category.id] = category))
    );
    return subCategoriesAtlas[subCategoryId]
      ? {
          id: subCategoriesAtlas[subCategoryId].id,
          name: subCategoriesAtlas[subCategoryId].name,
          columConfig: subCategoriesAtlas[subCategoryId].columConfig,
          endpoints:
            country === "ch" && subCategoriesAtlas[subCategoryId].endpointsCh
              ? subCategoriesAtlas[subCategoryId].endpointsCh
              : subCategoriesAtlas[subCategoryId].endpoints
        }
      : undefined;
  }

  public getTableDataAndConfig(
    subCategoryId: FactorSubCategories,
    dataSets: Array<IFactorResponseDtoMap[FactorEndpointId]>
  ): { config: Array<ColumnConfig>; data: TableData } {
    const subCategoryConf = this.getSubcategoryConfig(subCategoryId);

    if (!subCategoryConf || !dataSets.length) {
      return { config: [], data: [] };
    }

    const factorConfigs: Record<string, FactorProperty> = Object.assign(
      {},
      ...subCategoryConf.endpoints.map((endpoint) => FactorEndpointAttributes[endpoint])
    );

    return {
      config: this.getTableConfig(subCategoryId, factorConfigs),
      data: this.getDataForTable(subCategoryId, dataSets, factorConfigs)
    };
  }

  private getTableConfig(
    subCategoryId: FactorSubCategories,
    factorConfigs: Record<string, FactorProperty>
  ): Array<ColumnConfig> {
    const subCategoryConf = this.getSubcategoryConfig(subCategoryId);
    return [
      ...subCategoryConf.columConfig.map((columnConfig) => {
        return {
          ...columnConfig,
          translateAccessor: this.isTransportationFactorPerEnergySource(subCategoryId)
            ? factorConfigs[columnConfig.id]
              ? (value) => this.getFactorTranslation(value as string)
              : undefined
            : columnConfig.id === "name"
            ? (value) => this.getFactorTranslation(value as string, factorConfigs)
            : undefined
        } as ColumnConfig;
      }),
      // year columns
      ...Array.from(Array(LAST_YEAR - FIRST_YEAR + 1).keys()).map((index) => ({
        id: `${FIRST_YEAR + index}`,
        title: `${FIRST_YEAR + index}`
      }))
    ];
  }

  private getDataForTable(
    subCategoryId: FactorSubCategories,
    dataSets: Array<AnyFactorEmissionResponseDto>,
    factorConfigs: Record<string, FactorProperty>
  ): TableData {
    function getUnitConfigs(
      factorConfig: FactorProperty
    ): {
      unit: string;
      scalingFactor: number;
    } {
      const unitConfig = getUnitConfigFromFactorProperty(factorConfig);
      const displayedUnit = getDisplayedUnit(unitConfig);
      const scalingFactor = getScaleBetweenUnits(factorConfig.category, factorConfig.base, factorConfig.displayedUnit);
      return {
        unit: displayedUnit,
        scalingFactor
      };
    }

    return this.isTransportationFactorPerEnergySource(subCategoryId)
      ? (flatMap<any, any>(dataSets, (result: IFactorTransportation_VehicleDto) =>
          result.map((entry) => {
            return {
              // new added properties needs also to be removed when sending to endpoint
              fuelType: entry.fuelType,
              roadType: entry.roadType,
              vehicleType: entry.vehicleType,
              ...getUnitConfigs(factorConfigs["factorsByYear"]),
              ...entry.factorsByYear
            };
          })
        ) as TableData)
      : (flatMap<any, any>(dataSets, (result: Array<FactorGenericKeyValueDto<Record<string, FactorProperty>>>) =>
          Object.entries(result)
            .filter(([key]) => {
              if (factorConfigs[key]) {
                return true;
              }
              console.error(`Key ${key} inside date but config is missing`);
              return false;
            })
            .map(([key, value]) => {
              return {
                name: key,
                ...getUnitConfigs(factorConfigs[key]),
                ...value
              };
            })
        ) as TableData);
  }

  public getDataForPostEndpoints(
    subCategoryId: FactorSubCategories,
    tableData: TableData
  ): Array<{ endpointId: FactorEndpointId; data: IFactorResponseDtoMap[FactorEndpointId] }> {
    const subCategoryConf = this.getSubcategoryConfig(subCategoryId);

    return subCategoryConf.endpoints.map((endpointId) => {
      if (this.isTransportationFactorPerEnergySource(subCategoryId)) {
        return {
          endpointId,
          data: tableData.map((row) => {
            const { vehicleType, roadType, fuelType, unit, scalingFactor, unitConfig, ...factorsByYear } = row;
            return {
              vehicleType,
              roadType,
              fuelType,
              factorsByYear
            };
          }) as
            | IFactorResponseDtoMap[FactorEndpointId.Transportation_FactorKmPerEnergySource]
            | IFactorResponseDtoMap[FactorEndpointId.Transportation_FactorKwhPerKmPerEnergySource]
        };
      } else {
        const allFactors = Object.assign(
          {},
          ...tableData.map(({ unit, name, scalingFactor, unitConfig, ...factorsByYear }) => ({
            [name]: factorsByYear
          }))
        );

        const endpointAttributes = FactorEndpointAttributes[endpointId];

        return {
          endpointId,
          data: Object.assign(
            {},
            ...Object.keys(endpointAttributes)
              .filter((key) => allFactors[key])
              .map((key) => ({
                [key]: allFactors[key]
              }))
          )
        };
      }
    });
  }

  private isTransportationFactorPerEnergySource(subCategoryId: FactorSubCategories): boolean {
    return [
      FactorSubCategories.Transportation_FactorKmPerEnergySource,
      FactorSubCategories.Transportation_FactorKwhPerKmPerEnergySource
    ].includes(subCategoryId);
  }

  private getFactorTranslation(factorName: string, factorConfigs?: Record<string, FactorProperty>): string {
    if (!factorName) {
      return "";
    }
    const customTitle = factorConfigs?.[factorName]?.customTitle;
    switch (typeof customTitle) {
      case "undefined":
        return this.translate.instant(`RESOURCES.${factorName.toUpperCase()}`);
      case "boolean":
        return this.translate.instant(`DATA_PANEL.FACTOR_MANAGEMENT.FACTORS.${factorName.toUpperCase()}`);
      case "string":
        return this.translate.instant(`DATA_PANEL.FACTOR_MANAGEMENT.FACTORS.${customTitle.toUpperCase()}`);
    }
  }
}
